import { __decorate } from "tslib";
import { AdBannerModuleUi, Addon, AppTheme, LayoutContainer, LayoutSlot, ModuleDriver } from '@d24/modules/front';
import { Component } from 'vue-property-decorator';
import { PageBuilder } from '@component/PageBuilder';
import { ArticleSource } from '../ArticleSource';
import { Paywall } from '../Paywall';
import { Comments } from '../Comments';
import { StickySidebar } from '../StickySidebar';
import { TagsList } from '../TagsList';
import { WithLayout } from '../WithLayout';
import { Breadcrumb } from '@content/components/Breadcrumb';
/**
 * Component capable of rendering the article's contents.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let Article = class Article extends WithLayout {
    constructor() {
        super(...arguments);
        this.LayoutContainer = LayoutContainer;
    }
    /**
     * `ArticleTeaserModule` from `this.slots[LayoutSlot.Main]`.
     */
    get articleTeaserModule() {
        var _a, _b, _c, _d, _e, _f, _g;
        if (typeof this.slots === 'undefined' ||
            typeof this.slots[LayoutSlot.Main] === 'undefined' ||
            typeof ((_b = (_a = this.slots) === null || _a === void 0 ? void 0 : _a[LayoutSlot.Main]) === null || _b === void 0 ? void 0 : _b.modules) === 'undefined' ||
            !Array.isArray((_d = (_c = this.slots) === null || _c === void 0 ? void 0 : _c[LayoutSlot.Main]) === null || _d === void 0 ? void 0 : _d.modules)) {
            return;
        }
        const articleTeaserModule = (_g = (_f = (_e = this.slots) === null || _e === void 0 ? void 0 : _e[LayoutSlot.Main]) === null || _f === void 0 ? void 0 : _f.modules) === null || _g === void 0 ? void 0 : _g.find(
        // @ts-expect-error
        module => module.driver === ModuleDriver.ArticleTeaser);
        if (articleTeaserModule) {
            articleTeaserModule.content.theme = AppTheme.PremiumZone;
        }
        return articleTeaserModule;
    }
    get adBelowCommentsModules() {
        return this.commentsAdModule('below_comments');
    }
    get adAboveCommentsModules() {
        return this.commentsAdModule('below_comments', true);
    }
    get me() {
        return this.$store.getters['profile/me'];
    }
    /**
     * Determines whether the current application theme is `AppTheme.PremiumZone`.
     */
    get isPremiumZone() {
        var _a, _b;
        //   console.log('IS PREMIUM: ', this.me?.isPremium())
        if (((_a = this.me) === null || _a === void 0 ? void 0 : _a.isPremium()) && ((_b = this.me) === null || _b === void 0 ? void 0 : _b.isPaidPremium())) {
            return true;
        }
        return this.theme === AppTheme.PremiumZone;
    }
    /**
     * `ArticleTeaserModule` from `this.slots[LayoutSlot.Main]`.
     */
    get modulesWithoutArticleTeaser() {
        var _a, _b, _c, _d, _e, _f, _g;
        if (typeof this.slots === 'undefined' ||
            typeof this.slots[LayoutSlot.Main] === 'undefined' ||
            typeof ((_b = (_a = this.slots) === null || _a === void 0 ? void 0 : _a[LayoutSlot.Main]) === null || _b === void 0 ? void 0 : _b.modules) === 'undefined' ||
            !Array.isArray((_d = (_c = this.slots) === null || _c === void 0 ? void 0 : _c[LayoutSlot.Main]) === null || _d === void 0 ? void 0 : _d.modules)) {
            return;
        }
        return (_g = (_f = (_e = this.slots) === null || _e === void 0 ? void 0 : _e[LayoutSlot.Main]) === null || _f === void 0 ? void 0 : _f.modules) === null || _g === void 0 ? void 0 : _g.filter(module => module.driver !== ModuleDriver.ArticleTeaser);
    }
    get showComments() {
        return this.content ? this.content.getOption('comments', false) : false;
    }
    get source() {
        if (typeof this.content !== 'object' || this.content === null ||
            !this.content.hasProperty(Addon.Author)) {
            return null;
        }
        const authorAddon = this.content.getProperty(Addon.Author);
        return typeof authorAddon !== 'string' ? authorAddon.source || null : null;
    }
    get tags() {
        if (!this.content) {
            return [];
        }
        return this.content.properties.tags || [];
    }
    /**
     * Current application theme.
     */
    get theme() {
        return this.siteService.getActiveSiteTheme();
    }
    commentsAdModule(adUnit, not = false) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (typeof this.slots === 'undefined' ||
            typeof this.slots[LayoutSlot.Comments] === 'undefined' ||
            typeof ((_b = (_a = this.slots) === null || _a === void 0 ? void 0 : _a[LayoutSlot.Comments]) === null || _b === void 0 ? void 0 : _b.modules) === 'undefined' ||
            !Array.isArray((_d = (_c = this.slots) === null || _c === void 0 ? void 0 : _c[LayoutSlot.Comments]) === null || _d === void 0 ? void 0 : _d.modules)) {
            return;
        }
        if (not) {
            return (_g = (_f = (_e = this.slots) === null || _e === void 0 ? void 0 : _e[LayoutSlot.Comments]) === null || _f === void 0 ? void 0 : _f.modules) === null || _g === void 0 ? void 0 : _g.filter(module => {
                return !(module.driver === ModuleDriver.AdBanner && module.content.adUnit.indexOf(adUnit) > 0);
            });
        }
        return (_k = (_j = (_h = this.slots) === null || _h === void 0 ? void 0 : _h[LayoutSlot.Comments]) === null || _j === void 0 ? void 0 : _j.modules) === null || _k === void 0 ? void 0 : _k.find(module => module.driver === ModuleDriver.AdBanner && module.content.adUnit.indexOf(adUnit) > 0);
    }
    /**
     * Trigger page reload.
     */
    unlockArticle() {
        this.$emit('reload', { paywall: 'unlock' });
    }
    hasVideo(content) {
        return content &&
            typeof content.properties.cover === 'object' && (content === null || content === void 0 ? void 0 : content.properties.cover.video) &&
            (content.properties.cover.video.url !== '' ||
                (!!content.properties.cover.file && content.properties.cover.file.id !== '') ||
                content.properties.cover.video.onNetworkUrl !== '');
    }
    loadAds() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        window._taboola = window._taboola || [];
        // console.log('taboolaInitialLoad:', this.siteService.taboolaInitialLoad)
        if (!this.siteService.taboolaInitialLoad) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            window._taboola.push({ notify: 'newPageLoad' });
        }
        if (this.hasVideo(this.content)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            window._taboola.push({ video: 'auto' }); // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            window._taboola.push({
                mode: 'alternating-thumbnails-a',
                container: 'taboola-below-comments',
                placement: 'Below Comments',
                // eslint-disable-next-line @typescript-eslint/camelcase
                target_type: 'mix'
            });
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            window._taboola.push({
                mode: 'alternating-thumbnails-b',
                container: 'taboola-below-article-thumbnails',
                placement: 'Below Article Thumbnails',
                // eslint-disable-next-line @typescript-eslint/camelcase
                target_type: 'mix'
            });
        }
        else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            window._taboola.push({ article: 'auto' });
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            window._taboola.push({
                mode: 'alternating-thumbnails-a',
                container: 'taboola-below-comments',
                placement: 'Below Comments',
                // eslint-disable-next-line @typescript-eslint/camelcase
                target_type: 'mix'
            });
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            window._taboola.push({
                mode: 'alternating-thumbnails-b',
                container: 'taboola-below-article-thumbnails',
                placement: 'Below Article Thumbnails',
                // eslint-disable-next-line @typescript-eslint/camelcase
                target_type: 'mix'
            });
        }
        this.siteService.taboolaInitialLoad = false;
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        window._taboola.push({ flush: true });
    }
};
Article = __decorate([
    Component({
        name: 'Article',
        components: {
            AdBannerModuleUi,
            ArticleSource,
            Comments,
            PageBuilder,
            Paywall,
            StickySidebar,
            TagsList,
            Breadcrumb
        },
        mounted() {
            if (this.siteService.getAdsConfig().ads.taboola) {
                setTimeout(() => {
                    if (this.siteService.getAdsConfig().ads.taboola) {
                        this.loadAds();
                    }
                }, (this.siteService.getAdsConfig().taboolaDelay || 200));
            }
        }
    })
], Article);
export { Article };
export default Article;
